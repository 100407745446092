import { Component, Input, OnInit, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { ContentPage } from 'src/app/content/models';
import { JsonLdComponent } from 'src/app/seo/components/json-ld/json-ld.component';
import { SocialChannel } from 'src/app/social-media/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  styleUrls: ['./footer.component.scss'],
  imports: [FromDictionaryPipe, JsonLdComponent],
})
export class FooterComponent implements OnInit {
  private dict = new FromDictionaryPipe(this.lang);

  domain = input<string>('');
  menu = input<MenuItem[]>([]);
  socials = input<SocialChannel[]>([]);
  schema = input<any>({
    '@context': 'http://schema.org',
    '@type': this.dict.transform('footer-json-ld-meta-type'),
    'name': this.dict.transform('meta-titel'),
    'url': this.domain,
    'logo': `${this.domain}/assets/gfx/logo.svg`,
    'contactPoint': {
      '@type': 'ContactPoint',
      'email': this.dict.transform('contact-email'),
      'contactType': 'customer service',
    },
    'sameAs': [],
  });

  year = new Date().getFullYear();

  constructor(
    private store: Store,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.socials().forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema().sameAs.push(socialLink);
    });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
