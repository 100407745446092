<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()">
    @if (loaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [upperMenu]="('topmenu-upper' | fromMenu)?.error ? [] : ('topmenu-upper' | fromMenu)"
        [menu]="('topmenu-main' | fromMenu)?.error ? [] : ('topmenu-main' | fromMenu)"
      ></app-topbar>
    }

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    @if (loaded$()) {
      <app-footer [domain]="domainName"></app-footer>
    }
  </div>

  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>

  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
